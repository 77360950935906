import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Testimonials from '../components/Testimonials';
import Partners from '../components/Partners';
import { Partner } from '../partners';
import { Testimonial } from '../testimonials';
import { Element } from '../elements';
import ElementComponent from '../components/Element';
import { Menu } from '../menus';
import PFR from '../components/PFR';

const IntroContent = styled(Paper)`
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
`;

interface IndexProps {
  data: {
    cms: {
      elements: Element[];
      partners: Partner[];
      testimonials: Testimonial[];
      menus: Menu[];
    };
  };
}

const IndexPage = ({ data }: IndexProps) => {
  const {
    cms: { elements, testimonials, partners, menus },
  } = data;
  const home = menus.find((menu) => menu.slug === 'home');
  const card = home && home.cards && home.cards[0];
  return (
    <Layout>
      <SEO title="" />
      <Testimonials testimonials={testimonials} />
      <Grid>
        {card && (
          <Row>
            <Col xs={12}>
              <IntroContent>
                <h2>{card.title}</h2>
                <ReactMarkdown source={card.content} />
              </IntroContent>
            </Col>
          </Row>
        )}
      </Grid>

      {elements.map((element, index) => (
        <ElementComponent index={index} key={element.id} element={element} />
      ))}
      <PFR />
    </Layout>
  );
};

export const query = graphql`
  query {
    cms {
      testimonials {
        id
        author
        text
      }
      elements {
        id
        content
        link
        linkText
        background
        image {
          url
        }
      }
      menus {
        slug
        cards {
          title
          content
        }
      }
    }
  }
`;

export default IndexPage;
