import React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import PfrBar from '../images/pfr-bar.jpg';
import PfrLogo from '../images/pfr.jpg';

const Bar = styled.img`
  width: 100%;
  display: block;
  margin: 12px;
`;

const Logo = styled.img`
  width: 150px;
  margin: 12px;
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Disclaimer = styled(Col)`
  margin-bottom: 20px;
`;

const PFR = () => (
  <Grid
    style={{
      backgroundColor: '#FFF',
    }}
  >
    <Row>
      <Col xs={12}>
        <Bar src={PfrBar} alt="PFR" />
      </Col>

      <Col xs={12} md={2}>
        <LogoWrap>
          <Logo src={PfrLogo} alt="PFR" />
        </LogoWrap>
      </Col>
      <Disclaimer xs={12} md={10}>
        Przedsiębiorca usyskał dofinansowanie zwrotne w ramach programu Wsparcie
        Edukacji Przedszkolnej otrzymane przez Pomorski Fundusz Rozwoju
      </Disclaimer>
    </Row>
  </Grid>
);

export default PFR;
