import React, { FC } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BaseTitle from './BaseTitle';
import { Testimonial } from '../testimonials';

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <ChevronLeftIcon />,
  nextArrow: <ChevronRightIcon />,
};

const TestimonialContainer = styled.div`
  min-height: 180px;
  width: 280px;
  max-width: 100%;
  padding: 10px;
`;

const SliderContainer = styled.div`
  margin: auto;
  width: 400px;
  max-width: calc(100vw - 2em);
  margin-top: 25vh;
  margin-bottom: 10vh;
`;

const TestimonialFooter = styled.footer`
  margin-top: 10px;
`;

const Text = styled.p`
  font-size: 1.125rem;
  line-height: 1.7rem;
`;

const TestimonialItem: FC<Testimonial> = ({ text, author }) => (
  <TestimonialContainer>
    <Text>{text}</Text>
    <TestimonialFooter>{`— ${author}`}</TestimonialFooter>
  </TestimonialContainer>
);

const Container = styled(Paper)`
  padding: 40px;
  display: block;
  position: relative;
  border-radius: 1rem;
  .slick-prev,
  .slick-next {
    fill: ${(p) => p.theme.color.neutral};
    width: 40px;
    height: 40px;
    opacity: 0.7;
    transition: 0.2s all ease;
    &:hover {
      fill: ${(p) => p.theme.color.primary};
    }
  }
  .slick-prev {
    left: -35px;
  }
  .slick-next {
    right: -35px;
  }
  .slick-dots {
    bottom: -20px;
    li {
      width: 16px;
      height: 16px;
    }
    button {
      background: ${(p) => p.theme.color.bg};
      border-radius: 50%;
      width: 16px;
      height: 16px;
      position: relative;
      z-index: 2;
      border: 1px solid ${(p) => p.theme.color.neutral};
      opacity: 0.5;
      &:hover {
        border: 1px solid ${(p) => p.theme.color.primary};
      }
      &:before {
        content: ' ';

        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: ${(p) => p.theme.color.neutral};
        opacity: 1;
      }
    }
    .slick-active {
      button {
        opacity: 1;
        border: 1px solid ${(p) => p.theme.color.primary};
        &:before {
          background-color: ${(p) => p.theme.color.primary};
        }
      }
    }
  }
`;

const Title = styled(BaseTitle)`
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  /* margin: 1rem 2rem; */
  width: 100%;
  background: #fefefe;
`;

const Testimonials = ({ testimonials }: { testimonials: Testimonial[] }) => (
  <SliderContainer>
    {/* <Title>Rodzice o nas</Title> */}
    <Container>
      <Slider {...settings}>
        {testimonials.map((item) => (
          <TestimonialItem key={item.id} {...item} />
        ))}
      </Slider>
    </Container>
  </SliderContainer>
);

export default Testimonials;
