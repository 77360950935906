import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { readableColor } from 'polished';
import { Button } from '@material-ui/core';
import { Element } from '../elements';
import { getFullUrl } from './images';

const Content = styled.div`
  padding: 1rem;
  flex: 1;
  margin-bottom: 1rem;
  color: #fff;
  height: calc(100% - 1rem);
  border-radius: 1rem;
`;

// const colors = ['#07c0b3', '#ffa000', '#c73258'];

const Image = styled.img`
  object-fit: cover;
  background: gray;
  margin-bottom: 0;
  margin: 0;
  width: 100%;
  border-radius: 1rem;

  max-height: 460px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.33);
`;

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ElementRow = styled(Row)`
  min-height: 60vh;
  padding: 1.5rem 1rem;
  align-items: center;
  position: relative;
`;

const Bg = styled.div`
  &:nth-child(odd) {
    ${ElementRow} {
      flex-direction: row-reverse;
    }
  }
`;

const Href = styled(Button)`
  line-height: 2rem;
  font-weight: 700;
  text-transform: none;
  background-color: white;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.33);

  &:hover {
    background-color: ${(p) => p.theme.color.neutralLight};
    color: ${(p) => p.theme.color.primaryDark};
  }
`;

const ElementComponent = ({ element }: { element: Element; index: number }) => {
  const bg = element.background || '#efefef';
  const color = readableColor(bg);
  const { link, linkText, image } = element;
  return (
    <Bg
      style={{
        background: bg,
      }}
    >
      <Grid>
        <ElementRow>
          <Col md={6} xs={12}>
            {image && (
              <Wrap>
                <Image src={getFullUrl(image)} />
              </Wrap>
            )}
          </Col>
          <Col md={6} xs={12}>
            <Content
              style={{
                color,
              }}
            >
              <ReactMarkdown
                parserOptions={{ commonmark: true }}
                escapeHtml={false}
                source={element.content}
              />

              {!!link && (
                <Href href={link}>{linkText || 'Dowiedz się więcej'}</Href>
              )}
            </Content>
          </Col>
        </ElementRow>
      </Grid>
    </Bg>
  );
};

export default ElementComponent;
